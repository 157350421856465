html {
  direction: initial;
  overflow-x: hidden;
  font-family:"微软雅黑" !important;
}

html.rtl {
  direction: rtl;
}



body {
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  font-family: Moderat Regular, Microsoft YaHei;
  line-height: 1.5714285714285714;
  background: #ffffff;
  -webkit-transition: background-color 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: background-color 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}





.headerTopShadow {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 12px 0px;
  opacity: 0.9;
  backdrop-filter: blur(6px);
}

.logo {
  width: 100px;
  position: relative;
  top: 10px;
}

.headMenu {
  text-align: right;
  float: right;
  font-size: 16px;
  display: block;
  
  font-weight: bold;
}

/* .app_bg{
  position: absolute;
  top: 0;
  right:0;
  width: 100%;
} */
.step_box {
  text-align: center;
}

/*home*/
.homeBanner {
  font-family: Arboria,
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    Roboto,
    Arial,
    sans-serif;
}

.homeBanner h1 {
  margin: 10px 0;
}

.homeBanner_text_1 {
  font-family: 'deyi';
  font-size: 72px;
  color: #c5af84;
}

.homeBanner_text_2 {
  font-size: 28px;
  color: #000;
  
  position: relative;
}

.homeBanner_text_2 div {
  position: relative;
  z-index: 100;
}

.fill-blue-300\/70 {
  fill: #c5af84;
  position: absolute;
  left: 0;
  bottom: -12px;
  width: 300px;
  opacity: 0.5;
  z-index: 1;
}

.homeBanner_text_3 {
  font-size: 20px;
  
}

.contactButton {
  font-size: 20px;
  width: 100%;
  height: 50px;
  line-height: 40px;
  
}

.homePic_desc {
  
  font-size: 16px;
  padding: 10px 0;
}

.home_box {
  background: #fff;
  padding: 50px 0;
  font-size: 20px;
  text-align: center;
}

.home_box h1 {
  color: #c5af84;
}

.home_box_desc {
  padding: 0 20%;
}


.steps_box {
  margin: 10px 0;
}

.steps_box>div {
  background: #fff;
  height: 200px;
  
  padding: 20px;
}

.steps_box>div>h3 {
  text-align: center;
  font-size: 20px;
}

.steps_box>div>div {
  color: rgba(0, 0, 0, .3)
}



/*infinite*/
.infinite_info {
  position: relative;
  z-index: 2;
}

.infinite_title {
  font-family: "deyi";
  color: #000;
  text-align: center;
  font-size: 72px;
}

.infinite_title_1 {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(135deg, #f04ccc, #329dff);
  -webkit-background-clip: text;
}

.homeBannerBox{
  padding:20px;
}

.intro_box{
  padding:20px;
}
:where(.css-dev-only-do-not-override-1q6vjn0).ant-menu-horizontal{
  border-bottom: none;
}


.header_notice{
  -webkit-animation: textBg 1s ease-in;
  animation: textBg 1s ease-in;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}


  @keyframes textBg{
    0%{
      background-color: #ffe8bb;
    }
    100%{
      background-color: rgb(246 246 246);
    }
  }









/* 针对大屏幕设备的样式 */
@media only screen and (min-width: 960px) {

  /* 在屏幕宽度大于等于 960px 的设备上应用以下样式 */
  .headMenu {
    width: 100%;
  }

  .cowork_images {
    margin-bottom: 20px;
  }

}

/* 针对平板设备的样式 */
@media only screen and (min-width: 479px) and (max-width: 1024px) {
  .cowork_images {
    margin-bottom: 20px;
  }

  .homeBanner_text_1 {
    font-size: 30px;
  }

  .homeBanner_text_2 {
    font-size: 20px;
  }

  .fill-blue-300\/70 {
    width: 200px;
  }

  .homeBanner_text_3 {
    font-size: 16px;
  }

  .home_box {
    background: #fff;
    padding: 50px 0;
    font-size: 16px;
    text-align: center;
  }

  .home_box h1 {
    color: #c5af84;
    font-size: 30px;
  }

  .home_box_desc {
    padding: 0 20px;
  }
    .cowork_name{
      font-size: 20px;
    }
    .cowork_nametag{
      top:0;
    }
    :where(.css-dev-only-do-not-override-1q6vjn0).ant-layout-header {
      padding: 0 20px;
    }
}

/* 针对手机设备的样式 */
@media only screen and (max-width: 479px) {

  /* body{
    min-width:479px;
  } */
  .cowork_images {
    margin-bottom: 20px;
  }

  .homeBanner_text_1 {
    font-size: 30px;
  }

  .homeBanner_text_2 {
    font-size: 20px;
  }

  .fill-blue-300\/70 {
    width: 200px;
  }

  .homeBanner_text_3 {
    font-size: 16px;
  }

  .home_box {
    background: #fff;
    padding: 50px 0;
    font-size: 16px;
    text-align: center;
  }

  .home_box h1 {
    color: #c5af84;
    font-size: 30px;
  }

  .home_box_desc {
    padding: 0 20px;
  }
    .cowork_name {
      font-size: 20px;
    }
        .cowork_nametag {
          top: 0;
          left: 10px;
        }
        :where(.css-dev-only-do-not-override-1q6vjn0).ant-layout-header{
          padding:0 20px;
        }
}